import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "@layout/layout"
import { pl, en } from "@content/pages/documents.yaml"
import HeaderSubpage from "@layout/header-subpage"
import SEO from "@layout/seo"

export default ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <Layout isEn={isEn}>
      <SEO
        isEn={isEn}
        title={translation.metaTitle}
        description={translation.metaDescription}
      />
      <HeaderSubpage isEn={isEn}/>
      <section className="section wrapper">
        <section
          className="news-text padding wow fadeInUp"
          data-wow-duration="1.5s"
          style={{
            visibility: "hidden",
          }}
        >
          <div className="row">
            <div className="large-8 large-centered medium-12 small-12 columns">
              <h1 className="text-center">{translation.title}</h1>
            </div>
          </div>
        </section>
        {!isEn && (
          <section
            className="news-text padding wow fadeInUp"
            data-wow-duration="1.5s"
            style={{
              visibility: "hidden",
            }}
          >
            <div className="row">
              <div className="large-8 large-centered medium-12 small-12 columns">
                <p>
                  <strong>Poniżej lista dokumentów do pobrania:</strong>
                </p>
                <p>
                  Statut Fundacji SASA &gt;&gt;{" "}
                  <a href={withPrefix("files/statut.pdf")}>pobierz</a>
                </p>
                <p>
                  <strong>Rok 2016</strong>
                </p>
                <p>
                  Sprawozdanie merytoryczne z działalności w 2016
                  r&nbsp;&gt;&gt;{" "}
                  <a href={withPrefix("files/sprawmeryt2016.pdf")}>pobierz</a>
                  <br />
                  Rachunek zysków i strat 2016 &gt;&gt;{" "}
                  <a href={withPrefix("files/rach2016.png")}>pobierz</a>
                  <br />
                  Aktywa 2016 &gt;&gt;{" "}
                  <a href={withPrefix("files/aktywa2016.png")}>pobierz</a>
                  <br />
                  Pasywa 2016 &gt;&gt;{" "}
                  <a href={withPrefix("files/pasywa2016.png")}>pobierz</a>
                  <br />
                  Uchwała Zarządu 2016 &gt;&gt;{" "}
                  <a href={withPrefix("files/uchwala2016.png")}>pobierz</a>
                </p>
                <p>
                  <strong>Rok 2015</strong>
                </p>
                <p>
                  Bilans jednostek 2015 &gt;&gt;{" "}
                  <a href={withPrefix("files/bilans_jedn_2015.pdf")}>pobierz</a>
                  <br />
                  Bilans zysków i strat 2015 &gt;&gt;{" "}
                  <a href={withPrefix("files/bilans_zys_strat_2015.pdf")}>
                    pobierz
                  </a>
                  <br />
                  Sprawozdanie merytoryczne z działalności w 2015
                  r&nbsp;&gt;&gt;{" "}
                  <a href={withPrefix("files/spraw_meryt_2015.pdf")}>pobierz</a>
                </p>
              </div>
            </div>
          </section>
        )}
        {isEn && (
          <section
            className="news-text padding wow fadeInUp"
            data-wow-duration="1.5s"
            style={{
              visibility: "hidden",
            }}
          >
            <div className="row">
              <div className="large-8 large-centered medium-12 small-12 columns">
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Please find below a list of important documents to download:
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    SASA Foundation Statute
                  </span>{" "}
                  &gt;&gt; <a href={withPrefix("files/statut.pdf")}>download</a>
                </p>
                <p>
                  Balance sheet 2015 &gt;&gt;{" "}
                  <a href={withPrefix("files/bilans_jedn_2015.pdf")}>
                    download
                  </a>
                  <br />
                  Profit and loss in 2015 &gt;&gt;{" "}
                  <a href={withPrefix("files/bilans_zys_strat_2015.pdf")}>
                    download
                  </a>
                  <br />
                  Activity raport in 2015 &gt;&gt;{" "}
                  <a href={withPrefix("files/spraw_meryt_2015.pdf")}>
                    download
                  </a>
                </p>
              </div>
            </div>
          </section>
        )}
      </section>
    </Layout>
  )
}
